import React, { Component } from 'react';
import PortableText from '@sanity/block-content-to-react';
import serializers from './serializers';
import theme from '../styles/theme.module.scss';

function Rows(props) {
  const rows = props._rawPrivacyPolicyRows;
  return (
    <div className={`${theme.gdcont} ${theme.gmcont} ${theme.mT4} ${theme.mB2}`}>
      <h1
        className={`${theme.gdmain} ${theme.gmmain} ${theme.center} ${theme.h2_founders} ${theme.mB3}`}>
        Privacy Policy
      </h1>
      {rows &&
        rows.map((node, index) => (
          <div
            className={`${node.bigCopy ? theme.mB3 : theme.mB5} ${theme.gmmain} ${theme.gds3} ${
              theme.gde10
            }`}
            key={index}>
            <h2 className={`${theme.fd5} ${theme.h5_founders} ${theme.center} ${theme.mB8}`}>
              {node.title}
            </h2>
            <PortableText
              className={`${theme.portableText} ${node.bigCopy ? theme.h2_tiempos : ''} ${theme.base_content}`}
              renderContainerOnSingleChild={true}
              blocks={node.copy}
              serializers={serializers}
            />
          </div>
        ))}
    </div>
  );
}

export default Rows;
