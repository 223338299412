import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import SEO from "../components/seo";
import Rows from "../components/privacy-policy-rows.js";

export const query = graphql`
  query PrivacyPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SanitySiteSettingsFragment
      _rawPrivacyPolicyRows
      socialImage {
        asset {
          url
        }
      }
    }
  }
`;

const PrivacyPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} ogImage={site?.socialImage?.asset?.url}/>
      <Container>
        <Rows {...site} />
      </Container>
    </Layout>
  );
};

export default PrivacyPage;
